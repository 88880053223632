

import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { ValidationError } from 'class-validator';
import {
    DatePicker, Dialog, Input, Option, Select,
} from 'element-ui';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsModel from '@/modules/events/models/events.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import _ from 'lodash';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import { USER_LEVELS } from '@/modules/user/constants';
import EventGroup from '@/modules/events/interfaces/event-group.enum';

interface Item {
    value: number | string;
    name: string;
}

@Component({
    components: {
        EventTypeIcon,
        ValidationErrorComponent,
        CustomSelect,
        'el-dialog': Dialog,
        'el-date-picker': DatePicker,
        'el-input': Input,
        'el-select': Select,
        'el-option': Option,
    },
})

export default class AddEditEventForm extends Vue {
    @Inject(UserServiceS)
    public userService!: UserService;

    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    editableEvent: EventsModel | null = null;
    isSaving = false;
    errors: ValidationError[] = [];
    numberOfGuests_ = '';

    get numberOfGuests() {
        return this.numberOfGuests_;
    }

    set numberOfGuests(v: string) {
        const value = v.toLowerCase().trim();

        this.numberOfGuests_ = v;
        this.errors = [];

        if (value.endsWith('+')) {
            const valueWithoutPlus = +value.replace('+', '');

            if (!valueWithoutPlus || valueWithoutPlus < 0) {
                this.showNumberOfGuestsError('validation.invalidValue');
                return;
            }

            this.editableEvent!.numberOfGuests = [valueWithoutPlus];
            return;
        }

        if (value.includes('-')) {
            const values = value.split('-').map(v => +v);
            const incorrectRange = values.filter(v => Boolean(v) && v > 0).length !== values.length;

            if (values.length < 2 || values.length > 2 || incorrectRange) {
                this.showNumberOfGuestsError('validation.invalidValue');
                return;
            }

            this.editableEvent!.numberOfGuests = values.map(Number) as [number, number];
            return;
        }

        if (value.includes('upto') || value.includes('up to')) {
            const values = value.trim().replace(/\s+/g, '').split('upto').filter(Boolean);

            if (values.length !== 1) {
                this.showNumberOfGuestsError('events.validation.nogUpTo');
                return;
            }

            this.editableEvent!.numberOfGuests = [0, +values[0]];
            return;
        }

        if (!+value) {
            this.showNumberOfGuestsError('validation.invalidValue');
            return;
        }

        this.editableEvent!.numberOfGuests = [+value, +value];
    }

    get isNotHotelUser() {
        return ['chain', 'cluster'].includes(this.userService.viewAs!);
    }

    showNumberOfGuestsError(message: string) {
        this.errors.push({
            property: 'numberOfGuests',
            constraints: {
                isInvalid: message,
            },
            children: [],
        });
    }

    beforeMount() {
        const eventToSet = this.isNewEvent
            ? this.createEventModel()
            : this.getClonedEvent(this.$route.params.eventId);

        if (!this.isNewEvent) {
            const [a, b] = eventToSet!.numberOfGuests;

            if (a === b) {
                this.numberOfGuests_ = `${a}`;
            } else if (a === 0 && b !== 0) {
                this.numberOfGuests_ = `upto ${b}`;
            } else if (b === undefined) {
                this.numberOfGuests_ = `${a}+`;
            } else {
                this.numberOfGuests_ = `${a}-${b}`;
            }
        }

        this.$set(this, 'editableEvent', eventToSet);
    }

    mounted() {
        this.updatePickerRanges();
    }

    private createEventModel() {
        const event = new EventsModel();
        const branch = this.userService.routeBranch;

        const isHotelIdDefined = !!+this.$route.params.hotelId;
        event.entityType = isHotelIdDefined ? USER_LEVELS.HOTEL : branch as Omit<USER_LEVELS, USER_LEVELS.ONBOARDING>;

        switch (event.entityType) {
            case USER_LEVELS.CHAIN:
            case USER_LEVELS.CLUSTER:
                event.entityId = this.userService.chainId;
                event.group = EventGroup.CHAIN;
                break;

            case USER_LEVELS.HOTEL:
                event.entityId = +this.$route.params.hotelId;
                break;

            // NOTE: Default is cars user
            default:
                event.entityType = 'car';
                event.entityId = this.userService.id;
                break;
        }

        return event;
    }

    private updatePickerRanges() {
        const { datePicker } = this.$refs as { datePicker: any };

        datePicker.mountPicker();
        datePicker.showPicker();
        datePicker.hidePicker();

        setTimeout(() => {
            datePicker.picker.leftDate = new Date(this.documentFiltersService.year, this.documentFiltersService.month, 1);
            datePicker.picker.rightDate = new Date(this.documentFiltersService.year, this.documentFiltersService.month + 1, 1);
        });
    }

    get isNewEvent() {
        return (this.$route.name || '').includes('.new');
    }

    get startEndDates(): Date[] {
        if (!this.editableEvent) {
            return [];
        }

        const dates: Date[] = [];

        const correctedStartDate = new Date(this.editableEvent.startDate!);
        correctedStartDate.setMinutes(correctedStartDate.getTimezoneOffset());

        const correctedEndDate = new Date(this.editableEvent.endDate!);
        correctedEndDate.setMinutes(correctedEndDate.getTimezoneOffset());

        if (this.editableEvent.startDate) {
            dates.push(correctedStartDate);
        }
        if (this.editableEvent.endDate) {
            dates.push(correctedEndDate);
        }
        return dates;
    }

    set startEndDates(value: Date[]) {
        if (!this.editableEvent) {
            return;
        }

        if (value) {
            const [startDate, endDate] = value;
            this.editableEvent.startDate = new Date(startDate);
            this.editableEvent.endDate = new Date(endDate);
        } else {
            this.editableEvent.startDate = null;
            this.editableEvent.endDate = null;
        }
    }

    get options(): Item[] {
        return $enum(EVENT_TYPE_SETTINGS)
            .map((value): Item => ({
                value,
                name: this.$t(`events.type.${value}`).toString(),
            }));
    }

    async saveEvent() {
        const method = this.isNewEvent ? 'addEvent' : 'updateEvent';

        this.isSaving = true;
        this.errors = await this.eventsManagerService[method](this.editableEvent!);

        if (!this.errors.length) {
            this.closeModal();
            this.isSaving = false;
        }

        this.isSaving = false;
    }

    closeModal() {
        this.$emit('close');
    }

    private getClonedEvent(eventId: string) {
        const event = this.eventsManagerService.getEventById(eventId);
        return _.cloneDeep(event);
    }
}
